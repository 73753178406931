.MessageBackground{
    background-color: #F4F5F7;
    margin-top: 10px;
}

.SendMessageBtn {
    background-color: #794396;
    border-color: #794396;
    font-family: 'Inter';
    margin-top: 10px;
    font-weight: 700;
}