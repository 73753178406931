.LoginForm{
    margin: auto;
    margin-top: 20vh;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    width: 400px;
}

.LoginButton{
    width: 400px;
    background-color: #794396;
    border-color: #794396;
    font-weight: 700;
    font-family: 'Inter';
    margin-top: 84px;
}

.LoginPasswdInput{
    margin: auto;
    width: 400px;
}

.LoginUserInput{
    margin: auto;
    width: 400px;
    margin-bottom: 40px;
}

.LoginLogo{
    margin: auto;
    height: 37px;
    width: 254px;
    margin-bottom: 64px;
}

.LoginInstruction{
    font-weight: 700;
    font-family: 'Inter';
    align-self: flex-start;
    margin-bottom: 10px;
}