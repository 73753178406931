.OrderContent{
    margin-left: 280px;
    background-color: #F4F5F7;
    height: 100vh;
    overflow-y: scroll;
}

.DownloadBtn {
    background-color: #794396;
    border-color: #794396;
    font-family: 'Inter';
    margin-right: 200px;
    margin-top: 10px;
    font-weight: 700;
}

.DashboardViews{
    padding: 32px;
    display: flex;
}

.PDFCard{
    width: 10.5cm;
    height: 14.8cm;
}