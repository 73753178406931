.OpsViews{
    display: flex;
    flex-direction: column;
    padding: 32px;
}

.SelectedTab{
    color: #794396;
    font-weight: 700;
    font-family: 'Inter';
    border-bottom-color: #794396;
}

.OtherTab{
    color: gray;
    font-weight: 700;
    font-family: 'Inter';
}

.DateDisabledButton{
    background-color: #794396;
    border-color: #794396;
    font-family: 'Inter';
    margin-top: 20px;
    font-weight: 700;
    float: right;
    margin-left: 20px;
}