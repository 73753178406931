.TitleBlock{
    height: 88px;
    align-items: center;
    padding: 32px;
    display: flex;
}

.Greeting{
    font-family: 'Inter';
    font-weight: 700;
    font-size: 24px;
    align-self: left;
    height: 28px;
}