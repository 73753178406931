.Page{
    display: flex;
}

.Content{
    margin-left: 280px;
    width: 100vw;
    background-color: #F4F5F7;
    height: 100vh;
    overflow-y: scroll;
}

.DashboardViews{
    padding: 32px;
    display: flex;
}

.spinner {
    border: 4px solid #f3f3f3;
    border-top: 4px solid #86198f; /* Cor do spinner */
    border-radius: 50%;
    animation: spin 1s linear infinite;
  }
  
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }